/**
 * Compare date value with today (without time)
 */ 
 export function olderFromToday(date) {
  let today = new Date();
  let expire_date = new Date(date);

  today.setHours(0, 0, 0, 0);
  expire_date.setHours(0, 0, 0, 0);
  
  return expire_date<today;
}