<template>
  <div class="data-search">
  <form @submit="searchSubmit" method="get" autocomplete="off">
    <fieldset>
      <input  type="text" 
              name="q"
              v-model="search.q"
              placeholder="ค้นหาชื่อและรหัสโครงการ"
              autocomplete="off">

      <select name="progress" v-model="search.progress">
        <option value="">แสดงทุกสถานะ - -</option>
        <option value="50">รออนุมัติการเปิดเสนอราคา</option>
        <option value="90">กำลังเสนอราคา</option>
        <option value="180">รออนุมัติผู้ชนะ</option>
        <option value="190">การเสนอราคาเสร็จสิ้น</option>
      </select>
      <button type="submit">
        <i class="fa fa-search fa-lg"></i>
      </button>
    </fieldset>
  </form>
</div>
</template>

<script>
// import {cloneObject} from '@/functions/objects.js'

export default {
  data() {
    return {
      search: {
        q:'',
        progress:'',
        req_const: ''
      }
    }
  },
  created() {
    if(this.$route.query.q!==undefined) {
      this.search = this.$route.query;
    }
    if(this.$route.query.progress!==undefined) {
      this.progress = this.$route.progress;
    }
  },
  methods: {
    searchSubmit() {}
  }
}
</script>


<style scoped>
.data-search select { height: 31px; }
</style>