<template>
  <div>

    <div v-if="getLoaded()">
      <div class="data-control">
        <ProjectSearch />
        
        <div class="btn-set">
          <AddNewProjectBtnComp />
          <DownloadAllProjectFileBtnComp />
        </div>
      </div>

      <div v-if="projects.row==0">
        <EmptyBlockComp />
      </div>
      <section v-else class="data-list">
        <table class="tb-data">
          <colgroup>
            <col class="col-code" />
            <col />
            <col class="col-owner"/>
            <col class="col-cat" />
            <col class="col-location" />
            <col class="col-date" />
          </colgroup>
          <thead>
            <tr>
              <th>รหัส</th>
              <th>ชื่อโครงการ</th>
              <th>เจ้าของโครงการ</th>
              <th>ประเภทงาน</th>
              <th>ที่ตั้ง</th>
              <th>แก้ไขล่าสุด</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in projects.result" :key="i">
              <td>{{ item.code }}</td>
              <td>
                <div class="item-detail">
                  <router-link :to="'project/view/' + item.PID" class="item-title">{{ item.name }}</router-link>
                </div>
                <p class="meta">
                  <ProjectStatusLabel :item="item" />
                  <span class="meta-item">ผู้สนใจ {{ item.count_interested }}/{{ item.auction_limit }} ราย</span>
                  <span class="meta-item">ผู้เสนอราคา {{ item.count_auctioneer }}/{{ item.auction_limit }} ราย</span>
                </p>
                <ul v-if="haveEditRole()" class="item-action">
                  <li><router-link :to="'/project/view/' + item.PID" class="edit">แก้ไขข้อมูล</router-link></li>
                  <li><a href="#" @click.prevent="deleteItem(item.PID)" class="delete" >ลบ</a></li>
                </ul>
                <div v-if="item.complete==9" class="winner-info">
                  ผู้ชนะ:
                  <router-link :to="'/member/edit/'+item.winner_data.MID" target="_blank">{{ getMemberName(item.winner_data) }}</router-link>
                </div>
              </td>
              
              <td v-if="item.owner==0">-</td>
              <td v-if="item.owner">
                <router-link :to="'/member/edit/'+item.member.MID">
                  {{ getMemberName(item.member) }}
                </router-link>
              </td>
  
              <td>{{ item.cat | projectCatIdToName }}</td>
              <td>{{ item.province | nullTo('-') }}</td>
              
              <td>{{ item.date_modified | formatDate }}</td>
            </tr>
          </tbody>
  
          <tfoot>
            <tr>
              <th>รหัส</th>
              <th>ชื่อโครงการ</th>
              <th>เจ้าของโครงการ</th>
              <th>ประเภทงาน</th>
              <th>ที่ตั้ง</th>
              <th>แก้ไขล่าสุด</th>
            </tr>
          </tfoot>
        </table>
  
      </section>
      
      <div class="data-control control-foot">
        <Pagination slug='project' :limit="queryParams.limit" :total="projects.total" />
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {const_data_limit} from '@/variables/const.js';
import {cloneObject} from '@/utils/objects.js'

import EmptyBlockComp     from '@/components/common/EmptyBlockComp.vue'
import Pagination         from '@/components/common/Pagination.vue'
import ProjectSearch      from '@/components/project/ProjectSearch.vue'
import ProjectStatusLabel from '@/components/project/ProjectStatusLabel.vue'
import AddNewProjectBtnComp from '@/components/project/button/AddNewProjectBtnComp.vue'
import DownloadAllProjectFileBtnComp from '@/components/project/button/DownloadAllProjectFileBtnComp.vue'

export default {
  name: 'Project',
  page_title: 'Projects',
  data() {
    return {
      projects: {
        total: 0
      },
      queryParams:{
        limit: const_data_limit, 
        page: 1
      }
    }
  },
  components: {
    EmptyBlockComp,
    Pagination,
    ProjectSearch,
    ProjectStatusLabel,
    AddNewProjectBtnComp,
    DownloadAllProjectFileBtnComp,
  },
  watch:{
    $route() {
      this.loadPage();
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Projects');
    this.$store.commit('setHeadTitle', 'Projects');
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * have edit data permisstion
     */
    haveEditRole() {
      return this.$store.state.user.type>=50;
    },

    /**
     * load this page data
     */
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get( '/project', { params: this.prepareQueryParams() })
        .then( (res) => {
          if(res.data.status==200) {
            this.projects = res.data;
            this.projects.total = Number(this.projects.total);
          }

          this.$store.commit('setLoaderShow', false);
        })
      this.loaded = true;
    },
    
    deleteItem(id) { //
      let conf = confirm("Do you want to delete this item?");
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/project/delete', {project_id: id})
          .then( () => this.loadPage() );
      }
    },

    /**
     * prepare winner name
     */
    getMemberName(winner) {
      let fullname = winner.first_name+' '+winner.last_name;
      return (winner.business_type==9) ? winner.company_name : fullname;
    },


    /**
     * Prepare get params
     */
    prepareQueryParams() {
      if(this.$route.query.page){
        this.queryParams.page = this.$route.query.page;
      }

      let queryParams = cloneObject(this.queryParams);
      if(this.$route.query.q!==undefined) {
        Object.assign(queryParams, cloneObject(this.$route.query));
      }

      return queryParams;
    }
  }
}
</script>


<style scoped>
.btn-export {
  background: #b00;
  color: #fff;
}

.tb-data .col-code  {width: 130px;}
.tb-data .col-owner  {width: 15%;}
.tb-data .col-cat {width: 10%;}
.tb-data .col-location {width: 12%;}
.tb-data .col-date  {width: 15%;}
.tb-data .highlight {
  display: inline-block;
  color: #960;
}

.meta {
  margin-bottom: 8px;
  color: #888;
}
.meta-item {
  display: inline-block;
  margin-right: 10px;
}
.meta-status-close {
  color: #900;
}

.winner-info {
 margin-top: 10px;
}
</style>