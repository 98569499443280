<template>
  <a href="#" class="btn btn-add" @click="createNewProject">
    <i class="fa fa-plus"></i> Add <span class="mobile-hide">Project</span>
  </a>
</template>


<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  methods: {
    createNewProject() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .post( '/project/add')
        .then( (res) => {
          if(res.data.status==200) {
            this.$router.push('/project/add/step1/' + res.data.last_insert_id);
          }else{
            console.log(res.data);
            alert('เกิดข้อผิดพลาดในการสร้างโครงการใหม่ กรุณาลองใหม่อีกครั้ง');
            this.$store.commit('setLoaderShow', false);
          }
        })
    },
  }
}
</script>