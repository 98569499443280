<template>
  <a href="#" @click="clickExportExcel" class="btn btn-export">
    Export
  </a>
</template>


<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  methods: {
    /**
     * Click event handler for export member data to excel 
     */
    clickExportExcel() {
      apiRequest
        .get( '/project/export', { params: this.prepareQueryParams() })
        .then( (res) => {
          window.location.href = res.data.uri;
        })
    },
  }
}
</script>