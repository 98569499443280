<template>
  <span>
    <span v-if="item.agreement==9" class="status-label label-complete">
      <span class="bullet"></span>
      กำลังดำเนินการก่อสร้าง
      <!-- ได้ผู้ชนะการประมูลแล้ว -->
    </span>
    <span v-else-if="item.complete==9" class="status-label label-complete">
      <span class="bullet"></span>
      อยู่ระหว่างเซ็นสัญญา
      <!-- ได้ผู้ชนะการประมูลแล้ว -->
    </span>
    <span v-else-if="isWaitingWinnerApprove()" class="status-label label-expire">
      <span class="bullet"></span>
      อยู่ระหว่างผู้ดูแลระบบตรวจสอบ
    </span>
    <span v-else-if="isEndAuction()" class="status-label label-expire">
      <span class="bullet"></span>
      สิ้นสุดเวลาเสนอราคา
    </span>
    <span v-else-if="isAuctionFull()" class="status-label label-expire">
      <span class="bullet"></span>
      จำนวนผู้เสนอราคาเด็มแล้ว
    </span>
    <span v-else-if="isInteresterFull()" class="status-label label-expire">
      <span class="bullet"></span>
      จำนวนผู้สนใจเด็มแล้ว
    </span>
    <span v-else-if="isAuctioning()" class="status-label label-auction">
      <span class="bullet"></span>
      อยู่ระหว่างการประมูล
    </span>
    <span v-else class="status-label">
      <span class="bullet"></span>
      รอการอนุมัติ
    </span>
  </span>
</template>

<script>
import {olderFromToday} from '@/utils/date/olderFromToday.js';

export default {
  props: {
    item: Object
  },
  created() {
    // console.log(this.item);
  },
  methods: {
    // may bu date expire
    isEndAuction() {
      return this.item.approve==9 && olderFromToday(this.item.auction_end);
    },
    
    isWaitingWinnerApprove() {
      return this.item.winner_id!=0;
    },

    isAuctionFull() {
      return this.item.count_auctioneer>=this.item.auction_limit;
    },
    
    isInteresterFull() {
      return this.item.count_interested>=this.item.auction_limit;
    },

    isAuctioning() {
      return this.item.approve==9 && this.item.complete==0;
    },
  }
}
</script>

<style scoped>
.status-label {
  display: inline-block;
  margin-right: 10px;
}
.status-label .bullet {
  display: inline-block;
  margin-right: 2px;
  border-radius: 10px;
  width: 8px;
  height: 8px;
  background: #ccc;
}
.label-auction  .bullet  { background: #e90; }
.label-expire   .bullet  { background: #6cf; }
.label-complete .bullet { background: #690; }
</style>